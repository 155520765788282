// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDCjkIAJgq_soUSPuuOiCDRhT4ulgnGgMY",
    authDomain: "panel-keep.firebaseapp.com",
    projectId: "panel-keep",
    storageBucket: "panel-keep.appspot.com",
    messagingSenderId: "639644894720",
    appId: "1:639644894720:web:cb35d11c9b059983557ae5",
    measurementId: "G-RH71RP8H4T"
  }
  
};

